import { STATE_LOGIN, STATE_SIGNUP } from './components/AuthForm';
// import GAListener from './components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from './components/Layout';
import PageSpinner from './components/PageSpinner';
import AuthPage from './pages/AuthPage';
import ForgotPassword from './pages/ForgotPassword';
import ResetPasswordPage from './pages/ResetPasswordPage';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';

const AlertPage = React.lazy(() => import('./pages/AlertPage'));
const AuthModalPage = React.lazy(() => import('./pages/AuthModalPage'));
const BadgePage = React.lazy(() => import('./pages/BadgePage'));
const ButtonGroupPage = React.lazy(() => import('./pages/ButtonGroupPage'));
const ButtonPage = React.lazy(() => import('./pages/ButtonPage'));
const CardPage = React.lazy(() => import('./pages/CardPage'));
const Contacts = React.lazy(() => import('./pages/ContactPage'));
const Customer = React.lazy(() => import('./pages/TablePage'));
const ChartPage = React.lazy(() => import('./pages/ChartPage'));
const DashboardPage = React.lazy(() => import('./pages/DashboardPage'));
const DropdownPage = React.lazy(() => import('./pages/DropdownPage'));
const FormPage = React.lazy(() => import('./pages/FormPage'));
const InputGroupPage = React.lazy(() => import('./pages/InputGroupPage'));
const RespondMessage = React.lazy(() => import('./pages/repondMessage'));
const ModalPage = React.lazy(() => import('./pages/ModalPage'));
const ProgressPage = React.lazy(() => import('./pages/ProgressPage'));
// const TablePage = React.lazy(() => import('./pages/TablePage'));
const TypographyPage = React.lazy(() => import('./pages/TypographyPage'));
const WidgetPage = React.lazy(() => import('./pages/WidgetPage'));
const Logout = React.lazy(() => import('./pages/Logout'));
const Calender = React.lazy(() => import('./components/Calendar'));
const PendingMeeting = React.lazy(() => import('./pages/PendingMeetings'));
const AdminGuide = React.lazy(() => import('./pages/AdminGuide'));


// quotes section
const NewQuotes = React.lazy(() => import('./pages/NewQuotes'));
const ScheduleMeetingTime = React.lazy(() => import('./pages/ScheduleMeetingTime'));
const UpdateMeeting = React.lazy(() => import('./pages/UpdateMeetingTime'));

const ScheduledQuotes = React.lazy(() => import('./pages/ScheduledQuotes'));
const CompletedMeeting = React.lazy(() => import('./pages/CompletedMeeting'));
const DeclinedMeeting = React.lazy(() => import('./pages/DeclinedMeeting'));

// const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword'));

// const getBasename = () => {
//   return `/${process.env.PUBLIC_URL.split('/').pop()}`;
// };

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        {/* <GAListener> */}
        <Switch>
          <LayoutRoute
            exact
            path="/"
            layout={EmptyLayout}
            component={props => <AuthPage {...props} authState={STATE_LOGIN} />}
          />
          <LayoutRoute
            exact
            path="/signup"
            layout={EmptyLayout}
            component={props => (
              <AuthPage {...props} authState={STATE_SIGNUP} />
            )}
          />
          <LayoutRoute exact path="/forgot-password" layout={EmptyLayout} component={ForgotPassword} />
          <LayoutRoute exact path="/reset-password" layout={EmptyLayout} component={ResetPasswordPage} />


          <MainLayout breakpoint={this.props.breakpoint}>
            <React.Suspense fallback={<PageSpinner />}>
              <Route exact path="/dashboard" component={DashboardPage} />
              {/* <Route exact path="/forgot-password" component={ForgotPassword} /> */}
              <Route exact path="/login-modal" component={AuthModalPage} />
              <Route exact path="/buttons" component={ButtonPage} />
              <Route exact path="/quotes" component={CardPage} />
              {/* /contact-us-messages */}
              <Route exact path="/contact-us-messages" component={Contacts} />
              {/* customer */}
              <Route exact path="/customers" component={Customer} />
              <Route exact path="/widgets" component={WidgetPage} />
              <Route exact path="/typography" component={TypographyPage} />
              <Route exact path="/alerts" component={AlertPage} />
              {/* <Route exact path="/tables" component={TablePage} /> */}
              <Route exact path="/badges" component={BadgePage} />
              <Route exact path="/button-groups" component={ButtonGroupPage} />
              <Route exact path="/dropdowns" component={DropdownPage} />
              <Route exact path="/progress" component={ProgressPage} />
              <Route exact path="/modals" component={ModalPage} />
              <Route exact path="/forms" component={FormPage} />
              <Route exact path="/calendar" component={Calender} />
              <Route exact path="/guide" component={AdminGuide} />
              <Route exact path="/pending-meetings" component={PendingMeeting}/>
              <Route
                exact
                path="/quotes/respond/:id"
                component={InputGroupPage}
              />
              <Route
                exact
                path="/message/respond/:id"
                component={RespondMessage}
              />
              <Route exact path="/charts" component={ChartPage} />
              <Route exact path="/logout" component={Logout} />


              {/* quotes route section */}
              <Route exact path="/new-quotes" component={NewQuotes}/>
              <Route exact path="/schedule-appointment/:quoteId" component={ScheduleMeetingTime} />
              <Route exact path="/update-appointment/:quoteId" component={UpdateMeeting} />
              <Route exact path="/scheduled-appointment" component={ScheduledQuotes}/>
              <Route exact path="/completed-appointment" component={CompletedMeeting} />
              <Route exact path="/declined-appointment" component={DeclinedMeeting} />

            </React.Suspense>
          </MainLayout>
          <Redirect to="/" />
        </Switch>
        {/* </GAListener> */}
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
