/* eslint-disable no-useless-escape */
import logo200Image from '../assets/img/logo/new-logo.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label, CardText } from 'reactstrap';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


class ForgotForm extends React.Component {

  state = {
    email:'',
    loading: false,
    message: '',
    messageType: '',
  }

  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }

  changeAuthState = authState => event => {
    event.preventDefault();

    this.props.onChangeAuthState(authState);
  };

  redirect = redirectUrl => {
    window.location = redirectUrl;
  };

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({loading: true});

    const { email } = this.state;

    if(!email || email === ''){
      return this.setState({message: 'Email cannot be empty', messageType: 'error', loading: false});
    }

    if(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const check = re.test(String(email).toLowerCase());

      if(check === false){
        return this.setState({message: 'Email is invalid', messageType: 'error', loading: false});
      }
    }

    await fetch(`https://yess-boss-backend.herokuapp.com/api/v1/forgot-password`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      })
    }).then(response => response.json())
    .then(values => {
      if(values.status === 200){
        this.setState({messageType: 'success', message: 'Reset Password Link has been sent to your email', loading: false});
      }
      if(values.status !== 200){
        this.setState({message: values.error, messageType: 'error', loading: false});
      };
    }).catch(err => {
      this.setState({message: 'An error occurred, please reload and try again', messageType: 'error', loading: false});
    })
  };

  handleInputChange = ({ target }) => {
    this.setState({ [target.name]: target.value });

    console.log(target, 'STATE');
    
  };

  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return 'Send Reset Link';
    }

    if (!buttonText && this.isSignup) {
      return 'Signup';
    }

    return buttonText;
  }

  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      // passwordLabel,
      // passwordInputProps,
      // confirmPasswordLabel,
      // confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props;

    const {
      message,
      loading,
      messageType
    } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        {showLogo && (
          <div className="text-center pb-4">
            <img
              src={logo200Image}
              className="rounded"
              style={{ width: 60, height: 60, cursor: 'pointer' }}
              alt="logo"
              onClick={onLogoClick}
            />
            <br/>
            <p>Forgot Password</p>
          </div>
        )}
        <FormGroup>
          <Label for={usernameLabel}>{usernameLabel}</Label>
          <Input {...usernameInputProps} onChange={e => this.setState({ email: e.target.value })} />
        </FormGroup>
        {messageType === 'error' ? <CardText style={{color: 'red'}}>{message}</CardText> : <CardText style={{color: 'green'}}>{message}</CardText>}
        
        {/* {this.isSignup && (
          <FormGroup>
            <Label for={confirmPasswordLabel}>{confirmPasswordLabel}</Label>
            <Input {...confirmPasswordInputProps} />
          </FormGroup>
        )} */}
        {/* <a href="#">Forgot Password</a> */}
        <br/>
        {/* <FormGroup check>
          <Label check>
            <Input type="checkbox" />{' '}
            {this.isSignup ? 'Agree the terms and policy' : 'Remember me'}
          </Label>
        </FormGroup> */}
        {loading && <div style={{
            position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"
          }}>
          <Loader
          type="TailSpin"
          color="#00BFFF"
          height={80}
          width={80}
          style={{alignItem: 'center'}}
          // timeout={3000} //3 secs
  
       />
       </div>}
        <hr />
          <Button
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          onClick={this.handleSubmit}>
          {loading ? 'Loading..' : this.renderButtonText()}
        </Button>
          <br/>
        <a href="https://www.yessbossplumbing.org/" style={{textAlign: "center"}}>View the client Website</a>
        

        {/* <div className="text-center pt-1">
          <h6>or</h6>
          <h6>
            {this.isSignup ? (
              <a href="#login" onClick={this.changeAuthState(STATE_LOGIN)}>
                Login
              </a>
            ) : (
              <a href="#signup" onClick={this.changeAuthState(STATE_SIGNUP)}>
                Signup
              </a>
            )}
          </h6>
        </div> */}

        {children}
      </Form>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

ForgotForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

ForgotForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Enter your Yess Boss email',
  usernameInputProps: {
    type: 'email',
    placeholder: 'your@email.com',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};

export default ForgotForm;
